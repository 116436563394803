import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

export default function Signup(){

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const { createUser } = UserAuth()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            await createUser(email, password, firstName, lastName)
            navigate('/account')
        } catch (e){
            setError('Error, please try again.')
            //console.log(e.message)
        }
    }

    return(
        <section className='error bg-slate-100 h-screen pt-4 pb-[100%] md:pb-[15%]'>
        <div className='max-w-[700px] mx-auto my-16 p-4'>
            <div>
                <h1 className='text-2xl font-bold py-2 text-[#2C394B]'>Sign up for a free account</h1>
                <p className='py-2'>Already have an account? <Link className='underline' to='/'>Log in</Link></p>
            </div>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='firstName' className='py-2 font-semibold text-[#2C394B]'>First Name</label>
                        <input id='firstName' onChange={(e) => setFirstName(e.target.value)} className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='text' required />
                    </div>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='lastName' className='py-2 font-semibold text-[#2C394B]'>Last Name</label>
                        <input id='lastName' onChange={(e) => setLastName(e.target.value)} className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='text' required/>
                    </div>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='emaill' className='py-2 font-semibold text-[#2C394B]'>Email Address</label>
                        <input id='emaill' onChange={(e) => setEmail(e.target.value)} className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='email' required/>
                    </div>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='passwordd' className='py-2 font-semibold text-[#2C394B]'>Password</label>
                        <input id='passwordd' onChange={(e) => setPassword(e.target.value)} className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='password' required/>
                    </div>
                    <p>{error ? <span className='font-medium text-red-600 transition-1000 transition ease-in-out duration-[2000ms]'>Server busy. Please try again later.</span>:<span></span>}</p>
                    <button className='font-semibold border-[#2C394B] text-white hover:bg-[#36454F] bg-[#2C394B] w-full p-4 my-2 hover:text-white'>Sign Up</button>
                </form>
                </div>
        </section>
    )}