import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { motion } from 'framer-motion'

export default function Signin(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate()
    const { signIn } = UserAuth();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setError('Invalid Login details, please try again.')
        try{
            await signIn(email, password)
            navigate('/account')
        }catch (e) {
           setError('Invalid Login details. Please try again.')
            //console.log(e.message)
        }
        
    }

    return(
        <section className='error bg-slate-100 h-screen pt-4 pb-[100%] md:pb-[15%]'>
        <div className='max-w-[700px] mx-auto my-16 p-4'>
            <div>
                <h1 className='text-2xl font-bold py-2 text-[#2C394B]'>Log in to your account.</h1>

                {/* --------------------FOR NEW USERS-------------------- */}
                <p className='py-2 hidden'>Don't have an account yet? <Link className='underline' to='/signup'>Sign up</Link></p>
                {/* --------------------FOR NEW USERS-------------------- */}
           
            </div>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='email' className='py-2 font-bold text-[#2C394B]'>Email Address</label>
                        <input onChange={(e) => setEmail(e.target.value)} id='email' className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='email' required/>
                    </div>
                    <div className='flex flex-col py-2'>
                        <label htmlFor='password' className='py-2 font-bold text-[#2C394B]'>Password</label>
                        <input id='password' onChange={(e) => setPassword(e.target.value)} className='border font-semibold border-[#2C394B] text-[#2C394B] p-3 focus:outline-none focus:border-sky-500' type='password' required/>
                    </div>
                    <motion.div initial={{ opacity:0 }} animate={{ opacity: 1}} transition={{ delay: 2, duration: 2 }}>
                    <p>{ error ? <span className='font-medium text-red-600 transition-1000 transition ease-in-out duration-[2000ms]'>{error}</span>:<span className='hidden'></span>}</p>
                    </motion.div>
                    <button className='font-semibold border-[#2C394B] text-white hover:bg-[#36454F] bg-[#2C394B] w-full p-4 my-2 hover:text-white'>Log In</button>

                </form>
                
        </div>
        </section>
        )}
