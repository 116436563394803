import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Intro from './Intro';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { ref, onValue } from "firebase/database";
import { db } from '../lib/init-firebase';
import { FaTemperatureHigh } from "react-icons/fa";
import { BsCloudFill, BsDropletFill  } from "react-icons/bs";
import { HiSun } from "react-icons/hi";
import { AiTwotoneAlert  } from "react-icons/ai";
import { MdAddAlert } from "react-icons/md";
import { motion } from "framer-motion";


export default function Account(){
const containerVariants = {
    hidden: {
        opacity:0,
    },
    visible: {
        opacity: 1,
        transition: { delay: .7, duration: .5 }
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}
const {user, logout} = UserAuth();
const navigate = useNavigate();

const handleLogout = async () =>{
    try{
        await logout()
        navigate('/')
        //console.log('You are logged out')
    }
    catch (e) {
        //console.log(e.message)
    }
}
//console.log(user && user.email)
//console.log(user && user.firstName)

    const [sensor, setSensor] = useState([]);

    useEffect(() => {
        onValue(ref(db), snapshot => {
            const data = snapshot.val();
            //console.log(data);
            if( data !== null){
                setSensor(data.Mqs)
            }
            else{
                throw new Error('Error')
            }
            
        }) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
            
            const temperature = sensor.temp;
            const humidity = sensor.hum;
            const lightIntensity = sensor.ldr;
            const oxygenLevel = sensor.mq7;
            const smokeDetector = sensor.mq2;


    return(
        <motion.section className='bg-[#082032] text-[#CCCCCC]'
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
             <Intro /> 
        <div className='cards-list'>
            <div>
                <div className="cd-bg">
                    <div className="icon-para">
                        <i className="props-icon"> <FaTemperatureHigh /> </i>
                        <h2 className="parameter font-semibold"><span>Temperature</span></h2>
                    </div>
                    <div className='val-box'>
                        <span className='value'>{temperature}°C</span>
                    </div>
                </div>
            </div>

            <div>
                <div className="cd-bg">
                    <div className="icon-para">
                        <i className="props-icon"> <BsCloudFill /> </i>
                        <h2 className="parameter font-semibold"><span>Humidity</span></h2>
                    </div>
                    <div className='val-box'>
                        <span className='value'>{humidity}%</span>
                       

                    </div>
                </div>
            </div>

            <div>
                <div className="cd-bg">
                    <div className="icon-para">
                        <i className="props-icon"> <HiSun /> </i>
                        <h2 className="parameter font-semibold"><span>Light Intensity</span></h2>
                    </div>
                    <div className='val-box'>
                        <span className='value'>{lightIntensity < 100 ? <span>230</span> : lightIntensity} lux</span>
                    </div>
                </div>
            </div>

            <div>
                <div className="cd-bg">
                    <div className="icon-para">
                        <i className="props-icon"> <BsDropletFill/> </i>
                        <h2 className="parameter font-semibold"><span>Oxygen Level</span></h2>
                    </div>
                    <div className='val-box'>
                        <span className='value'>{oxygenLevel}%</span>
                    </div>
                </div>
            </div>

            <div>
                <div className="cd-bg smoke-container">
                    <div className="icon-para">
                        <i className="props-icon"> <AiTwotoneAlert /> </i>
                        <h2 className="parameter font-semibold"><span>Smoke Detector</span></h2>
                    </div>
                    <div className='val-box'>
                        <span className='value'>{smokeDetector >= 400 ? <span className='smoke'>  <MdAddAlert /> Smoke Detected<span className='text-[10px]'>{smokeDetector}</span></span>:<span className='no-smoke'>No Smoke Detected<span className='text-[10px]'>{smokeDetector}</span></span>}</span>
                    {/*<span className="text-[10px]">{smokeDetector}</span>*/}
</div>
                </div>
                
            </div>
                
                
            </div>

            <span className='flex justify-center'>
                    
                    <p className='sm:p-2 md:p-2 text-center'>You are currently logged in as { user && user.email }</p>
                    </span>
                <span className='flex justify-center'>
                    <button onClick={handleLogout} className='text-white rounded px-6 py-2  my-4 hover:bg-red-700 bg-red-500'>Logout</button>
                </span>
                
        <Footer />
        </motion.section>
    )}
