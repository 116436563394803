import React from 'react';
import Signin from './components/Signin';
import Account from './components/Account';
import Signup from './components/Signup';
import Error from './components/Error';
import{ Route, Routes, useLocation } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import { FaHandHoldingMedical } from "react-icons/fa";
import { AnimatePresence } from 'framer-motion';

export default function App(){
   const location = useLocation();
    return(
        <main>
            <header>
            <nav>
            
                <i className='text-[#F3F3E7] text-3xl md:text-4xl'><a href ='https://www.hems.vercel.app'><FaHandHoldingMedical /></a></i>
                <h1 className='large-name font-semibold'><a href ='https://www.hems.vercel.app'>Hospital Environment Monitoring System</a></h1>
                <h1 className='small-name font-semibold text-2xl'><a href ='https://www.hems.vercel.app'>HEMS</a></h1>
            
            </nav>
        </header>
        <AuthContextProvider>
            <AnimatePresence>
            <Routes location={location} key={location.key}>
                
                <Route path='/' element={<Signin />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/account' element={<ProtectedRoute><Account /></ProtectedRoute>} />
                <Route path='*' element={<Error />}/>

            </Routes>
            </AnimatePresence>
        </AuthContextProvider>
        
        </main>
    );
}