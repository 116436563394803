import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../lib/firebase';


const UserContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({})
const createUser = (email, password, firstName) => {
    return createUserWithEmailAndPassword(auth, email, password, firstName)
}

const signIn = (email, password, firstName) => {
    return signInWithEmailAndPassword(auth, email, password, firstName)
}
const logout = () => {
    return signOut(auth)
}

useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        //console.log(currentUser);
        setUser(currentUser);
    })
    return () => {
        unsubscribe();
    }
},[])
    return(
        <UserContext.Provider value={{ createUser, user, logout, signIn }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}