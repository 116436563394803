import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCRu-AO5brraYPN_5ps5Iz_VHNaj5JDuaM",
  authDomain: "new-auth-8c45f.firebaseapp.com",
  projectId: "new-auth-8c45f",
  storageBucket: "new-auth-8c45f.appspot.com",
  messagingSenderId: "908123251380",
  appId: "1:908123251380:web:40f319ba48ba1a20c96de1",
};
// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// }
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app
export const db = getDatabase(app);