import React from 'react';

export default function Intro(){
    const location = 'Hospital'
    const date = new Date();
    const hours = date.getHours();
    let timeOfDay;
    
    if (hours < 12) {
        timeOfDay = "morning"
    } else if (hours >= 12 && hours < 17) {
        timeOfDay = "afternoon"
    } else {
        timeOfDay = "evening"
    }
    return(
        <section className='section-intro'>
            <div>
                <h2 className="let-start">Good {timeOfDay}.</h2>
                <p className='condition'>These are the current weather conditions in the {location}.</p>
            </div>
        </section>
    )
}
