import React from 'react';
import img from '../images/img.svg';
import { motion } from "framer-motion";

const Error = () => {
    const containerVariants = {
    hidden: {
        opacity:0,
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 }
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}
  return (
    <section className='bg-slate-300 pt-4 h-[94vh] pb-[60%] error'>
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        
    >
        
        <div className='grid place-items-center mt-2'>
            <img src={img} alt='Error' className='w-[40%]'/>
        </div>
        <div className='grid place-items-center text-center'>
            <h2 className='uppercase text-2xl font-semibold mt-3 mb-2 text-gray-800'>Page not found</h2>
            <p className='font-medium text-gray-800'>The page you're looking for does not exist.</p>
            <p className='my-2 font-medium text-gray-800'>We suggest you go back to the homepage</p>
        </div>
        <div className='grid place-items-center mt-2'>
        <a href = 'https://www.hems.vercel.app' class="relative inline-block text-lg group">
<span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-700 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
<span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
<span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
<span class="relative uppercase font-semibold">homepage</span>
</span>
<span class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
</a>
</div>
    </motion.div>
    </section>
  )
}

export default Error