 ////MUSTECH DATABASE STARTS HERE
 import { initializeApp } from "firebase/app";
 import { getDatabase } from 'firebase/database';
 
 
 const secondaryAppConfig = {
  apiKey: "AIzaSyCvO65cITxEl14r749FD6A3BrvuGdxFouo",
  authDomain: "esp22-4b263.firebaseapp.com",
  projectId: "esp22-4b263",
  storageBucket: "esp22-4b263.appspot.com",
  messagingSenderId: "907040245981",
  appId: "1:907040245981:web:c6072f88446fb17f81b334",
  measurementId: "G-GS702TBX44",
  databaseURL: "https://esp22-4b263.firebaseio.com",
};
 
 const secondaryApp = initializeApp(secondaryAppConfig, "secondary");

 export const db = getDatabase(secondaryApp);
 ////MUSTECH DATABASE ENDS HERE

////MY OWN DATABASE STARTS HERE
// import { initializeApp } from 'firebase/app';
// import { getDatabase } from 'firebase/database';

// const firebaseConfig = {
//     apiKey: "AIzaSyDzoMsmYshPh4FJ-XSkLzj_5mX8Z5rno2Y",
//     authDomain: "realtime-learn.firebaseapp.com",
//     projectId: "realtime-learn",
//     storageBucket: "realtime-learn.appspot.com",
//     messagingSenderId: "77361912333",
//     appId: "1:77361912333:web:d6cb0d27b62d9040cb9344",
//     databaseURL: "https://realtime-learn-default-rtdb.europe-west1.firebasedatabase.app/"
//   };

//   const app = initializeApp(firebaseConfig);

//   export const db = getDatabase(app);

////MY OWN DATABASE ENDS HERE

  
 

















































// THE OLD FIRESORE CONFIG STAYS HERE
// import { initializeApp } from 'firebase/app'
// import { getFirestore } from 'firebase/firestore'
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyD5h4Booa6srswYio8yKhwzZDJRMP8yZpk",
//     authDomain: "react-firebase-basic-3feb4.firebaseapp.com",
//     projectId: "react-firebase-basic-3feb4",
//     storageBucket: "react-firebase-basic-3feb4.appspot.com",
//     messagingSenderId: "879095133394",
//     appId: "1:879095133394:web:dc476267416af719b2e90b",
//     measurementId: "G-C2LXZ9G3NF"
//   };

//   const app = initializeApp(firebaseConfig);

//   //init firestore
//   export const db =  getFirestore(app)